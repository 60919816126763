<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="ver_nuevo(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <v-row>
                <v-col cols="6" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    autofocus
                    outlined
                    dense
                    @change="filtrar_locales()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtrar_locales()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Categoria
                  <v-autocomplete
                    v-model="filtro.categoria"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="categorias"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtrar_locales()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="py-1">
                  Punto de venta
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  CUIL
                  <v-text-field-integer
                    v-model.trim="filtro.cuil"
                    type="number"
                    tabindex="1"
                    v-bind:properties="{
                      'hide-details': true,
                      outlined: true,
                      dense: true
                    }"
                    v-bind:options="{
                      inputMask: '##-########-#',
                      outputMask: '###########',
                      empty: null
                    }"
                  />
                </v-col>
                <v-col cols="6" md="2" class="py-1">
                  Nº Documento
                  <v-text-field
                    v-model.trim="filtro.documento"
                    type="number"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="2" class="d-flex justify-center align-center py-1">
                  <v-switch
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    tabindex="1"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-switch>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  clase="mt-0"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <!-- DATA TABLE -->
      <v-data-table
        class="cebra elevation-2 mt-2"
        sort-by="nombre"
        :headers="headers"
        :items="vendedores"
        :loading="load"
        :search="search"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                v-model="search"
                label="Buscar"
                hide-details
                single-line
                outlined
                dense
              >
                <template v-slot:append>
                  <v-icon
                    class="mt-1"
                    title="Buscar en los datos filtrados"
                    small
                  >
                    fas fa-search
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="ver_nuevo(item.codigo)"
          >
            fas fa-eye
          </v-icon>
          <v-icon
            class="mr-2"
            :color="item.usuario ? 'indigo' : 'success'"
            :title="item.usuario ? 'Usuario' : 'Crear usuario'"
            :disabled="!item.usuario && item.inhabilitado !== 'NO'"
            small
            @click="item.usuario ? open_dialog(item, 'dialog_user') : open_dialog(item, 'dialog_crear_user')"
          >
            fas fa-user{{ item.usuario ? '' : '-plus' }}
          </v-icon>
          <BtnConfirmar
            clase="mr-2"
            :icono="item.inhabilitado === 'NO' ? 'fas fa-ban' : 'fas fa-check'"
            :color="item.inhabilitado === 'NO' ? 'error' : 'success'"
            :title="item.inhabilitado === 'NO' ? 'Inhabilitar' : 'Habilitar'"
            :texto="item.inhabilitado === 'NO'
              ? `¿Desea inhabilitar al vendedor <strong>${item.nombre}</strong>?`
              : `¿Desea habilitar al vendedor <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado === 'NO' ? inhabilitar(item) : habilitar(item)"
          />
          <v-icon
            v-if="item.inhabilitado === 'NO'"
            color="deep-purple"
            title="Puntos de venta"
            class="mr-2"
            small
            @click="open_dialog(item, 'dialog_ptovta')"
          >
            fas fa-store
          </v-icon>
          <v-icon
            v-if="item.inhabilitado === 'NO'"
            color="light-green"
            title="Dedicación horaria"
            small
            @click="open_dialog(item, 'dialog_objetivo')"
          >
            fas fa-percentage
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>

      <!-- COMPONENTES -->
      <ModalUsuario
        v-model="dialog_user"
        :usuario="item_sel.usuario"
      />

      <ModalPtoVta
        v-model="dialog_ptovta"
        :vendedor="item_sel.codigo"
        :sucursal="item_sel.codigo_suc"
        :empresa="item_sel.empresa"
        :canal="item_sel.canal"
      />

      <EditarDedicHoraria
        v-model="dialog_objetivo"
        :vendedor="item_sel.codigo"
        :refrescar="dialog_objetivo"
      />
 
      <CrearUsuario
        v-model="dialog_crear_user"
        :vendedor="item_sel.codigo"
        :sucursal="item_sel.codigo_suc"
        :empresa="item_sel.empresa"
        :nombre="item_sel.nombre"
        :categoria="item_sel.categoria"
        :vendedores_list.sync="vendedores"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import ModalUsuario from '../../components/util/ModalUsuario'
import ModalPtoVta from '../../components/util/ModalPtoVta'
import EditarDedicHoraria from '../../components/vendedores/EditarDedicHoraria'
import CrearUsuario from '../../components/vendedores/CrearUsuario'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      dialog_user: false,
      dialog_ptovta: false,
      dialog_objetivo: false,
      dialog_crear_user: false,
      item_sel: {},
      categorias: [],
      vendedores: [],
      locales: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Sucursal', value: 'sucursal' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Usuario', value: 'usuario' },
        { text: 'Categoria', value: 'categoria_nombre' },
        { text: 'Inhabilitado', value: 'inhabilitado', sortable: false, filterable: false },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      filtro: {
        empresa: null,
        sucursal: null,
        local: [],
        inhabilitado: 0,
        codigo: null,
        nombre: '',
        cuil: null,
        documento: null,
        categoria: null
      },
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('vendedores/get_locales_user')
    await this.$store.dispatch('vendedores/get_cat_vendedor')
      .then((data) => {
        this.categorias = data.categorias
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
    // si tiene solo 1 empresa la selecciona
    if (this.una_empresa) {
      this.filtro.empresa = this.empresas[0].id
    }
    // si tiene solo 1 sucursal la selecciona
    if (this.una_sucursal) {
      this.filtro.sucursal = this.sucursales[0].id
    }
  },
  computed: {
    ...mapGetters(['una_empresa', 'una_sucursal']),
    ...mapState(['empresas', 'sucursales'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    ModalUsuario,
    ModalPtoVta,
    EditarDedicHoraria,
    CrearUsuario,
  },
  methods: {
    filtrar_locales () {
      this.locales = []
      const empresa = this.filtro.empresa
      const sucursal = this.filtro.sucursal
      const categoria = this.filtro.categoria
      
      if (empresa) {
        // filtra por empresa
        this.locales = this.$store.state.vendedores.locales_user.filter(loc => loc.empresa == empresa)
        // filtra por sucursal
        this.locales = this.locales.filter(loc => loc.sucursal == sucursal || sucursal == null)
        // filtra por categoria
        if (categoria) {
          const canal = this.categorias.find(cat => cat.id == categoria).canal
          this.locales = this.locales.filter(loc => loc.canal == canal)
        }
      }
    },
    async buscar () {
      if (!this.filtro.empresa) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar una empresa',
          color: 'orange'
        })
      }
      this.load = true
      this.search = ''
      this.vendedores = []
      await this.$store.dispatch('vendedores/get_vendedores', this.filtro)
        .then((data) => {
          this.vendedores = data.vendedores
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('vendedores/inhabilitar_vendedor', { vendedor: item.codigo })
        .then((res) => {
          const index = this.vendedores.indexOf(item)
          this.vendedores.splice(index, 1)

          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('vendedores/habilitar_vendedor', { vendedor: item.codigo })
        .then((res) => {
          const index = this.vendedores.indexOf(item)
          this.vendedores.splice(index, 1)

          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    ver_nuevo (id) {
      let path = '/vendedor'
      if (id) {
        path = `${path}/${id}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    open_dialog (item, dialogName) {
      this.item_sel = item
      this[dialogName] = true
    },
    limpiar () {
      this.filtro = {
        empresa: null,
        sucursal: null,
        local: [],
        inhabilitado: 0,
        codigo: null,
        nombre: '',
        cuil: null,
        documento: null,
        categoria: null
      }
    }
  }
}
</script>