<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :persistent="load || $store.state.load_pvta_modal"
  >
    <v-card>
      <v-card-title>
        Modificar puntos de venta
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load || $store.state.load_pvta_modal"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <PtoVta
          :vendedor="vendedor"
          :sucursal="sucursal"
          :empresa="empresa"
          :canal="canal"
          :ptovta_v.sync="ptosvta_vendedor"
          :disabled="load"
          :refrescar="dialog"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load || $store.state.load_pvta_modal"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          :disabled="$store.state.load_pvta_modal"
          @action="guardar"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PtoVta from '../vendedores/PtoVta'
import BtnConfirmar from './BtnConfirmar'

export default {
  data () {
    return {
      load: false,
      ptosvta_vendedor: [],
    }
  },
  components: {
    PtoVta,
    BtnConfirmar,
  },
  props: {
    value: Boolean,
    vendedor: Number,
    sucursal: Number,
    empresa: Number,
    canal: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    clase: {
      type: String,
      default: '',
    },
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async guardar () {
      this.load = true
      
      // format data
      let locales = await JSON.parse(JSON.stringify(this.ptosvta_vendedor))
      locales.forEach(local => {
        delete local.empresa
        delete local.local_accesorios
        delete local.nombre
        delete local.sucursal
        if (!local.favorito) {
          local.favorito = null
        }
      })

      await this.$store.dispatch('vendedores/asignar_ptosvta', { vendedor: this.vendedor, locales: locales })
        .then(async (res) => {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })

      this.load = false
    }
  }
}
</script>