<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
    :persistent="load || load_save"
  >
    <v-card>
      <v-card-title>
        Editar dedic. horaria
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load || load_save"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-center pt-6 pb-2 px-0">
        <v-col cols="8" class="py-0">
          <v-text-field
            v-model.trim="objetivo"
            ref="obj"
            label="Dedicación horaria"
            type="number"
            suffix="%"
            :rules="[rules.required, rules.range]"
            :loading="load"
            :readonly="load"
            validate-on-blur
            autofocus
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          :disabled="load || load_save"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          :disabled="load"
          :loading="load_save"
          @click="guardar"
        >
          <v-icon left>fas fa-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      load_save: false,
      objetivo: null,
      obj_original: null,
      rules: {
        required: value => !!value || 'Campo requerido',
        range: value => (value >= 0 && value <= 100) || 'Rango de 0 a 100',
      }
    }
  },
  props: {
    value: Boolean,
    vendedor: Number,
    refrescar: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    refrescar (val) {
      if (val) {
        this.get_obj()
      } else {
        this.objetivo = null
      }
    },
  },
  methods: {
    async get_obj () {
      this.load = true
      await this.$store.dispatch('vendedores/get_obj_vendedor', { vendedor: this.vendedor })
        .then(async (data) => {
          this.objetivo = data
          this.obj_original = await JSON.parse(JSON.stringify(this.objetivo))
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
      this.$refs.obj.focus()
    },
    async guardar () {
      if (this.$refs.obj.validate()) {
        this.load_save = true
        if (this.obj_original !== this.objetivo) {
          const data = {
            vendedor: this.vendedor,
            objetivo: this.objetivo,
          }

          await this.$store.dispatch('vendedores/update_obj_vendedor', data)
            .then((res) => {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        }
        this.dialog = false
        this.load_save = false 
      }
    }
  }
}
</script>