<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    :persistent="$store.state.load_user_modal"
  >
    <v-card>
      <v-card-title>
        Modificar usuario
        <v-spacer></v-spacer>
        <v-btn
          :disabled="$store.state.load_user_modal"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <Usuarios
          :card="true"
          :user="usuario"
          :refrescar="dialog"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Usuarios from '../../views/generales/Usuarios'

export default {
  components: {
    Usuarios,
  },
  props: {
    value: Boolean,
    usuario: String,
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>