<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar localidad
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-form @submit.prevent="buscar_loc">
          <v-row class="mx-4 mt-0 mb-3">
            <v-col cols="12" md="8" class="py-1">
              Nombre
              <v-text-field
                v-model.trim="nombre"
                hide-details
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="d-flex justify-end align-end py-1">
              <v-btn
                color="info"
                type="submit"
                :loading="load"
              >
                <v-icon left>fas fa-search</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          v-model="selected"
          checkbox-color="primary"
          :headers="headers"
          :items="localidades"
          :loading="load"
          single-select
          show-select
          dense
        >
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="selected.length"
          color="success"
          @click="select_loc"
        >
          <v-icon left>fas fa-check</v-icon>
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      nombre: '',
      selected: [],
      localidades: [],
      headers: [
        { text: 'Código postal', value: 'cp' },
        { text: 'Localidad', value: 'nombre' },
      ],
    }
  },
  props: ['cp'],
  props: {
    value: Boolean,
    loc: {
      default: '',
      type: String,
    },
    cl: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    localidad: {
      get () {
        return this.loc
      },
      set (value) {
        this.$emit('update:loc', value)
      }
    },
    cod_loc: {
      get () {
        return this.cl
      },
      set (value) {
        this.$emit('update:cl', value)
      }
    },
    cod_post: {
      get () {
        return this.cp
      },
      set (value) {
        this.$emit('update:cp', value)
      }
    },
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async buscar_loc () {
      if (!this.nombre) {
        this.$store.dispatch('show_snackbar', {
          text: 'Nombre es obligatorio',
          color: 'warning',
        })
        return
      }
      this.load = true
      this.selected = []
      this.localidades = []
      await this.$store.dispatch('get_loc_x_nombre', { nombre: this.nombre })
        .then((data) => {
          this.localidades = data.localidades
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    select_loc () {
      const loc = this.selected[0]
      this.localidad = loc.nombre
      this.cod_loc = loc.id
      this.cod_post = loc.cp
      this.dialog = false
    },
    clear () {
      this.nombre = ''
      this.selected = []
      this.localidades = []
    }
  }
}
</script>