<template>
  <v-row class="pt-2">
    <v-col v-if="!disabled" cols="12" class="pb-0">
      (*Arraste los puntos de venta de acuerdo a la configuración deseada)
    </v-col>
    <v-col cols="12" md="5">
      <v-card :disabled="disabled" :style="disabled_card">
        <v-card-title class="px-0 py-1" style="font-size: 18px">
          <v-btn
            :disabled="load"
            icon
            @click="select_all(ptosvta_vendedor)"
          >
            <v-icon>
              {{
                selected.find(item => ptosvta_vendedor.find(i => i.id === item.id))
                ? (selected.length === ptosvta_vendedor.length ? 'fas fa-check-square' : 'fas fa-minus-square')
                : 'far fa-square'
              }}
            </v-icon>
          </v-btn>
          Puntos de venta asignados
        </v-card-title>
        <v-divider></v-divider>
        <v-card
          height="250px"
          style="overflow-y: auto;"
          :loading="load"
          flat
        >
          <v-skeleton-loader
            v-if="load"
            class="mx-auto"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <template v-else>
            <v-card-text class="pa-0" style="height: 100%">
              <drop-list
                :items="ptosvta_vendedor"
                class="list"
                style="height: 100%"
                mode="cut"
                @insert="onInsert($event, 'ptosvta_vendedor')"
                @reorder="$event.apply(ptosvta_vendedor)"
              >
                <template v-slot:item="{item}">
                  <drag
                    :key="item.id"
                    class="item"
                    :class="{ 'selected' : selected.indexOf(item) > -1 }"
                    :data="selection(item)"
                    go-back
                    @click="toggleSelected(ptosvta_vendedor, item)"
                    @cut="remove(ptosvta_vendedor, item)"
                  >
                    {{item.nombre}}
                    <v-icon v-if="item.favorito" color="amber" small right>fas fa-star</v-icon>
                  </drag>
                </template>
                <template v-slot:feedback="{data}">
                  <template v-if="selected.length > 0">
                    <div v-for="f in data" :key="f.nombre" class="item feedback">{{f.nombre}}</div>
                  </template>
                  <template v-else>
                    <div :key="data.nombre" class="item feedback">{{data.nombre}}</div>
                  </template>
                </template>
              </drop-list>
            </v-card-text>
          </template>
        </v-card>
      </v-card>
    </v-col>
    <v-col
      cols="12" md="2"
      class="d-flex px-0"
      :class="$vuetify.breakpoint.mdAndUp ? 'align-center' : 'justify-center'"
    >
      <v-row class="d-flex justify-center text-center" :no-gutters="$vuetify.breakpoint.mdAndUp">
        <v-col cols="2" md="12">
          <v-btn
            color="primary"
            title="Quitar todos"
            class="mb-md-2"
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="load || disabled"
            @click="mover_all(ptosvta, ptosvta_vendedor)"
          >
            <v-icon>fas fa-angle-double-{{ $vuetify.breakpoint.mdAndUp ? 'right' : 'down' }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" md="12">
          <v-btn
            color="primary"
            title="Quitar seleccionado/s"
            class="mb-md-6"
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="load || disabled"
            @click="mover(ptosvta, ptosvta_vendedor)"
          >
            <v-icon>fas fa-angle-{{ $vuetify.breakpoint.mdAndUp ? 'right' : 'down' }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" md="12">
          <v-btn
            color="primary"
            title="Agregrar seleccionado/s"
            class="mb-md-2"
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="load || disabled"
            @click="mover(ptosvta_vendedor, ptosvta)"
          >
            <v-icon>fas fa-angle-{{ $vuetify.breakpoint.mdAndUp ? 'left' : 'up' }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" md="12">
          <v-btn
            color="primary"
            title="Agregar todos"
            class="mb-md-4"
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="load || disabled"
            @click="mover_all(ptosvta_vendedor, ptosvta)"
          >
            <v-icon>fas fa-angle-double-{{ $vuetify.breakpoint.mdAndUp ? 'left' : 'up' }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" md="12">
          <v-btn
            color="amber"
            class="mx-md-2"
            :title="selected.length === 1 ? (selected[0].favorito === 1 ? 'Quitar favorito' : 'Marcar como favorito') : ''"
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="load || disabled || !(selected.length === 1 && selected.find(item => ptosvta_vendedor.find(i => i.id === item.id)))"
            icon
            @click="marcar_fav"
          >
            <v-icon>fas fa-star</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="5">
      <v-card :disabled="disabled" :style="disabled_card">
        <v-card-title class="px-0 py-1" style="font-size: 18px">
          <v-btn
            :disabled="load"
            icon
            @click="select_all(ptosvta)"
          >
            <v-icon>
              {{
                selected.find(item => ptosvta.find(i => i.id === item.id))
                ? (selected.length === ptosvta.length ? 'fas fa-check-square' : 'fas fa-minus-square')
                : 'far fa-square'
              }}
            </v-icon>
          </v-btn>
          Puntos de venta sin asignar
        </v-card-title>
        <v-divider></v-divider>
        <v-card
          height="250px"
          style="overflow-y: auto;"
          :loading="load"
          flat
        >
          <v-skeleton-loader
            v-if="load"
            class="mx-auto"
            type="list-item-three-line"
          ></v-skeleton-loader>
          <template v-else>
            <v-card-text class="pa-0" style="height: 100%">
              <drop-list
                :items="ptosvta"
                class="list"
                style="height: 100%"
                mode="cut"
                @insert="onInsert($event, 'ptosvta')"
                @reorder="$event.apply(ptosvta)"
              >
                <template v-slot:item="{item}">
                  <drag
                    :key="item.nombre"
                    class="item"
                    :class="{ 'selected' : selected.indexOf(item) > -1 }"
                    :data="selection(item)"
                    go-back
                    @cut="remove(ptosvta, item)"
                    @click="toggleSelected(ptosvta, item)"
                  >
                    {{item.nombre}}
                  </drag>
                </template>
                <template v-slot:feedback="{data}">
                  <template v-if="selected.length > 0">
                    <div v-for="f in data" :key="f.nombre" class="item feedback">{{f.nombre}}</div>
                  </template>
                  <template v-else>
                    <div :key="data.nombre" class="item feedback">{{data.nombre}}</div>
                  </template>
                </template>
              </drop-list>
            </v-card-text>
          </template>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd"

export default {
  data () {
    return {
      load: false,
      ptosvta: [],
      selected: [],
      selectedList: 0,
    }
  },
  created () {
    if (this.nuevo) {
      this.calcular_array()
    } else {
      this.get_locales()
    }
  },
  components: {
    Drag,
    DropList,
  },
  props: {
    vendedor: Number,
    sucursal: Number,
    empresa: Number,
    canal: Number,
    ptovta_v: Array,
    /*
      ptovta_copy mantiene una copia originial del array cuando hace la primer consulta.
      Es utilizado para volver al estado anterior (cuando se cancelan ediciones) sin necesidad de ejecutar nuevamente la misma consulta
    */
    ptovta_copy: Array,
    copy_active: {
      type: Boolean,
      default: false,
    },
    filtrar: {
      type: Boolean,
      default: false,
    },
    nuevo: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     *  este prop sirve para refrescar los datos cuando se selecciona otro
     *  item de la lista (solo se usa en el componente ModalPtoVta)
     */
    refrescar: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ptosvta_vendedor: {
      get () {
        return this.ptovta_v
      },
      set (value) {
        this.$emit('update:ptovta_v', value)
      }
    },
    filtrar_locales: {
      get () {
        return this.filtrar
      },
      set (value) {
        this.$emit('update:filtrar', value)
      }
    },
    copy: {
      get () {
        return this.ptovta_copy
      },
      set (value) {
        this.$emit('update:ptovta_copy', value)
      }
    },
    disabled_card(){
      return [ this.disabled ? { 'background-color' : '#f0f0f0' } : {} ]
    },
  },
  watch: {
    refrescar (val) {
      if (val) {
        this.get_locales()
      } else {
        this.ptosvta = []
        this.selected = []
        this.selectedList = 0
      }
    },
    async filtrar_locales (val) {
      if (val) {
        this.set_load(true)
        this.ptosvta_vendedor = []
        await this.calcular_array()
        this.filtrar_locales = false
        this.set_load(false)
      }
    },
    async copy_active (val) {
      if (val) {
        this.set_load(true)
        this.ptosvta = []
        this.selected = []
        this.ptosvta_vendedor = await JSON.parse(JSON.stringify(this.copy))
        await this.calcular_array()
        this.set_load(false)
      }
    }
  },
  methods: {
    async get_locales () {
      this.set_load(true)
      await this.$store.dispatch('vendedores/get_locales_vendedor', { vendedor: this.vendedor })
        .then(async (data) => {
          this.copy = await JSON.parse(JSON.stringify(data.ptos_vta))
          this.ptosvta_vendedor = data.ptos_vta
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.calcular_array()
      this.set_load(false)
    },
    async calcular_array () {
      this.ptosvta = await this.$store.state.vendedores.locales_user.filter(ptovta => ptovta.sucursal === this.sucursal && ptovta.empresa === this.empresa && ptovta.canal === this.canal)
      // calcula la diferencia
      this.ptosvta = await this.ptosvta.filter(a => !this.ptosvta_vendedor.some(b => a.id === b.id))
    },
    async mover (lista_agregar, lista_sacar) {
      await this.selected.forEach(async (item) => {
        if (lista_sacar.find(i => i.id === item.id)) {
          const index = lista_sacar.indexOf(item)
          lista_sacar[index].favorito = ''
          await lista_sacar.splice(index, 1)
          await lista_agregar.push(item)
        }
      })
      this.selected = []
    },
    async mover_all (lista_agregar, lista_sacar) {
      await lista_sacar.forEach(async (item) => {
        item.favorito = ''
        await lista_agregar.push(item)
      })
      await lista_sacar.splice(0, lista_sacar.length)
      this.selected = []
    },
    select_all (items) {
      if (this.selected.length === 0) {
        items.forEach(item => {
          this.toggleSelected(items, item)
        })
      } else {
        this.selected = []
      }
    },
    async marcar_fav () {
      let fav = false
      if (this.selected[0].favorito === 1) {
        fav = true
      }
      await this.ptosvta_vendedor.forEach(item => {
        item.favorito = ''
      })
      if (!fav) {
        this.selected[0].favorito = 1 
      }
      this.selected = []
    },
    set_load (bool) {
      this.load = bool
      this.$store.state.load_pvta_modal = bool
    },
    // metodos del dnd
    selection(item) {
      return this.selected.length > 0 ? this.selected : item
    },
    onInsert(event, listName = "ptosvta_vendedor") {
      if (event.data.length > 0) {
        event.data.forEach((e, idx) => {
          if (!this[listName].find(item => item.id === e.id)) {
            this[listName].splice(event.index + idx, 0, e)
          }
        })
      } else {
        if (!this[listName].find(item => item.id === event.data.id)) {
          this[listName].splice(event.index, 0, event.data)
        }
      }
      this.selected = []
    },
    remove(array, value) {
      if (this.selected.length > 0) {
        this.selected.forEach(e => {
          if (array.find(item => item.id === e.id)) {
            let index = array.indexOf(e)
            array[index].favorito = ''
            array.splice(index, 1)
          }
        })
      } else {
        let index = array.indexOf(value)
        array[index].favorito = ''
        array.splice(index, 1)
      }
    },
    toggleSelected(listName, item) {
      if (listName !== this.selectedList) {
        this.selected = []
        this.selectedList = listName
      }
      const index = this.selected.indexOf(item)
      if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(item)
      }
    }
  }
}
</script>

<style>
.list {
  padding: 1px;
}
.item {
  padding: 2px 6px;
  margin: 3px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.selected {
  background-color:  var(--v-primary-base);
  color: #ffff;
}
.feedback {
  border: 1px dashed var(--v-primary-base);
}
.drag-image {
  background-color: rgb(220, 255, 220);
  transform: translate(-50%, -50%);
}
</style>